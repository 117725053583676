import React, { useEffect, useState, useRef } from "react";
import { Button, Image } from "react-bootstrap";
import MultiChecboxSelector from "../controls/MultiCheckboxSelector";
import NumericSliderSelector from "../controls/NumericSliderSelector";
import Logo from "../resources/2022kmtwhitesm.png";

import InputForm from "./InputForm";
import { useScreenshot } from "use-react-screenshot";
import { products } from "../utils/calcs";
import SplashScreen from "./SplashScreen";
import PascalHeader from "./PascalHeader";

export default function SavingsCalculator({scaleVal}) {
  const [savedPerMonth, setSavedPerMonth] = useState(0);
  const [savedPerYear, setSavedPerYear] = useState(0);
  const [clicksPerMonth, setClicksPerMonth] = useState(0);
  const [hoursPerMonth, setHoursPerMonth] = useState(0);
  const acronymName = "Monthly Savings Calculator";
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [resetVals, setResetVals] = useState(false);
  const [showInputFormModal, setShowInputFormModal] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(screenRef.current);

  const screenRef = useRef();

  const [expandedCtls, setExpandedCtls] = useState([
    {
      index: 0,
      expanded: false,
      val: null,
    },
    {
      index: 1,
      expanded: false,
      val: null,
    },
    {
      index: 2,
      expanded: false,
      val: null,
    },
  ]);

  useEffect(() => {
    calcValues();
  }, [expandedCtls]);

  useEffect(() => {
    if (resetVals) setResetVals(false);
  }, [resetVals]);

  function getExpanded(index) {
    return expandedCtls[index].expanded;
  }

  function setExpanded(index, val) {
    var tmp = [...expandedCtls];
    tmp[index].expanded = val;
    if (val === true) {
      expandedCtls.forEach((x) => {
        if (x.index !== index && x.expanded === true) {
          x.expanded = false;
        }
      });
    }
    setExpandedCtls([...tmp]);
  }

  function setControlValue(index, val) {
    var tmp = [...expandedCtls];
    if (tmp[index].val !== val) {
      tmp[index].val = val;
      setExpandedCtls([...tmp]);
    }
  }

  function resetValues() {
    clearValues();
    setShowInputFormModal(false);
    setShowSplashScreen(true);
  }

  function clearValues() {
    setResetVals(true);
    setExpandedCtls([
      {
        index: 0,
        expanded: false,
        val: 100,
      },
      {
        index: 1,
        expanded: false,
        val: null,
      },
      {
        index: 2,
        expanded: false,
        val: 80,
      },
    ]);

    setSavedPerMonth(0);
    setSavedPerYear(0);
    setClicksPerMonth(0);
    setHoursPerMonth(0);
  }

  function calcValues() {
    setSavedPerMonth(calculateSavedPerMonth());
    setSavedPerYear(calculateSavedPerYear());
    setClicksPerMonth(calculateClicksPerMonth());
    setHoursPerMonth(calculateHoursPerMonth());
  }

  function calculateSavedPerMonth() {
    var total = 0;

    var closedLoans = expandedCtls[0].val ?? 0;
    var avgsalary = expandedCtls[2].val ?? 0;

    for (let i = 0; i < selectedSolutions.length; i++) {
      var prod = products.find((x) => x.Id === selectedSolutions[i].key);
      if (prod) {
        if (prod?.Calcs.length > 1) {
          var mcalc = prod?.Calcs[1];

          if (mcalc != null) {
            var result = mcalc(prod.Id, closedLoans, avgsalary);
            total += result;
          }
        }
      }
    }
    return total;
  }

  function calculateSavedPerYear() {
    var total = 0;

    var closedLoans = expandedCtls[0].val ?? 0;
    var avgsalary = expandedCtls[2].val ?? 0;

    for (let i = 0; i < selectedSolutions.length; i++) {
      // selectedSolutions[i];
      var prod = products.find((x) => x.Id === selectedSolutions[i].key);
      if (prod) {
        if (prod?.Calcs.length > 1) {
          var mcalc = prod?.Calcs[1];

          if (mcalc != null) {
            var result = mcalc(prod.Id, closedLoans, avgsalary);
            total += result;
          }
        }
      }
    }
    return total * 12;
  }

  function calculateClicksPerMonth() {
    var total = 0;

    var closedLoans = expandedCtls[0].val ?? 0;
    var avgsalary = expandedCtls[2].val ?? 0;

    for (let i = 0; i < selectedSolutions.length; i++) {
      // selectedSolutions[i];
      var prod = products.find((x) => x.Id === selectedSolutions[i].key);
      if (prod) {
        if (prod?.Calcs.length > 0) {
          var ccalc = prod?.Calcs[0];

          if (ccalc != null) {
            var result = ccalc(prod.Id, closedLoans, avgsalary);
            total += result;
          }
        }
      }
    }
    return total;
  }

  function calculateHoursPerMonth() {
    var total = 0;

    var closedLoans = expandedCtls[0].val ?? 0;
    var avgsalary = expandedCtls[2].val ?? 0;

    for (let i = 0; i < selectedSolutions.length; i++) {
      var prod = products.find((x) => x.Id === selectedSolutions[i].key);
      if (prod) {
        if (prod?.Calcs.length > 2) {
          var hcalc = prod?.Calcs[2];

          if (hcalc != null) {
            var result = hcalc(prod.Id, closedLoans, avgsalary);
            total += result;
          }
        }
      }
    }
    return total;
  }

  function showInputForm() {
    getImage();
    setShowInputFormModal(true);
  }
  
  function getTools() {
    var ret = [];
    for (let i = 0; i < selectedSolutions.length; i++) {
      var prod = products.find((x) => x.Id === selectedSolutions[i].key);
      
      if (prod) {
        ret.push(prod.Name);
      }
    }
    return ret;
  }

  function getMoneyPerMonth() {
    return formatter.format(savedPerMonth);
  }
  function getMoneyPerYear() {
    return formatter.format(savedPerYear);
  }
  function gtClicksPerMonth() {
    var tmp = clicksPerMonth;
    var retString = getFormattedNumber(tmp).toString();
    if (tmp > 0) {
      if (tmp < 1000) {
        retString = retString + " Clicks";
      } else {
        tmp = tmp / 1000;
        retString = getFormattedNumber(tmp).toString() + "K Clicks";
      }
    }
    else {
      return "0";
    }

    return retString;

    // return getFormattedNumber(tmp).toString() + 'K Clicks';
  }
  function getHoursPerMonth() {
    if (hoursPerMonth > 0) {
      return getFormattedNumber(hoursPerMonth).toString() + " Hours";
    }
    return "0";
  }

  const getFormattedNumber = (input) => {
    input = Math.round(input);
    return input.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <SplashScreen
        setShowSplashScreen={setShowSplashScreen}
        showSplashScreen={showSplashScreen}
      />

      <InputForm
        showModal={showInputFormModal}
        setShowModal={setShowInputFormModal}
        acronymName={acronymName}
        Logo={Logo}
        resetApp={resetValues}
        getImage={getImage}
        image={image}
        monthlyClicks={gtClicksPerMonth}
        monthlyDollars={getMoneyPerMonth}
        tools={getTools}
        loanCount={expandedCtls[0].val ?? 0}
      />

      <div className="outer-cont">
        <div
          className={[
            "page-container",
            showInputFormModal ? "hidden" : "",
          ].join(" ")}
        >
          <div className={["page-header-row", "mw-row"].join(" ")}>
            <div>
              <Image src={Logo} className="kmt-logo" />
            </div>
          </div>

          <PascalHeader />

          <div className={["control-row", "mw-row"].join(" ")}>
            <NumericSliderSelector
              labelText={"Average # of Closed Loans Per Month"}
              defaultVal={"Average # of"}
              expanded={getExpanded(0)}
              setExpanded={setExpanded}
              index={0}
              minVal={0}
              maxVal={5000}
              step={10}
              setControlValue={setControlValue}
              resetVal={resetVals}
              defaultStartVal={100}
            />
          </div>

          <div className={["control-row", "mw-row"].join(" ")}>
            <MultiChecboxSelector
              labelText={"KMT Automations/Tools/Solutions"}
              defaultVal={"Choose Which"}
              cbOptions={products}
              selectedOptions={selectedSolutions}
              setSelectedOptions={setSelectedSolutions}
              expanded={getExpanded(1)}
              setExpanded={setExpanded}
              index={1}
              setControlValue={setControlValue}
              resetVal={resetVals}
            />
          </div>

          <div className={["control-row", "mw-row"].join(" ")}>
            <NumericSliderSelector
              labelText={"Average Production Staff Salary"}
              defaultVal={""}
              expanded={getExpanded(2)}
              setExpanded={setExpanded}
              index={2}
              minVal={0}
              maxVal={250}
              step={5}
              abbrev={"K"}
              isMoney={true}
              setControlValue={setControlValue}
              resetVal={resetVals}
              defaultStartVal={80}
            />
          </div>

          <div className={["summary-section-grid"].join(" ")} ref={screenRef}>
            <div className={["summ-block", "tl"].join(" ")}>
              <div className={["summ-hdr-mny"].join(" ")}>
                {getMoneyPerMonth()}
              </div>

              <div className={["summ-hdr"].join(" ")}>
                Dollars Saved Per Month
              </div>
            </div>

            <div className={["summ-block", "tr"].join(" ")}>
              <div className={["summ-hdr-cnt"]}>{gtClicksPerMonth()}</div>
              <div className={["summ-hdr"]}>Clicks Saved Per Month</div>
            </div>

            <div className={["summ-block", "bl"].join(" ")}>
              <div className={["summ-hdr-mny"].join(" ")}>
                {getMoneyPerYear()}
              </div>
              <div className={["summ-hdr"].join(" ")}>
                Dollars Saved Per Year
              </div>
            </div>

            <div className={["summ-block", "br"].join(" ")}>
              <div className={["summ-hdr-cnt"]}>{getHoursPerMonth()}</div>
              <div className={["summ-hdr"]}>Hours Saved Per Month</div>
            </div>
          </div>

          <div className={["page-footer", "mw-row"].join(" ")}>
            <Button
              className={["kmt-savings-button"].join(" ")}
              variant="secondary"
              onClick={resetValues}
            >
              Reset
            </Button>
            <Button
              className={["kmt-savings-button"].join(" ")}
              variant="secondary"
              onClick={clearValues}
            >
              Clear
            </Button>
            <div />
            <Button
              className={["kmt-savings-button", "save"].join(" ")}
              variant="success"
              onClick={showInputForm}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
